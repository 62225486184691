<template>
    <div class="welcome container">
        <h2>Nová rezervace</h2>

        <label v-show="isAdmin" for="userId">ID uzivatele</label>
        <input v-show="isAdmin" id="userId" type="text" placeholder="Uzivatelske ID (1212)" v-model="reservation.userId"/>

        <label id="spaceId">Prostor</label>
        <select id="spaceId" class="form-select" v-model="reservation.spaceId">
            <option disabled value="">Vyber prostor</option>
            <option v-for="option in spaces" :value="option.id" :key="option.id">
                {{ option.spaceId }}
            </option>
        </select>

        <label for="from">Datum platnosti od (2022-05-01)</label>
        <input id="from" type="text" placeholder="Datum od (2022-05-01)" v-model="reservation.from"/>

        <label for="to">Datum platnosti do (2022-10-01)</label>
        <input id="to" type="text" placeholder="Datum do (2022-02-27)" v-model="reservation.to"/>

        <label for="start">Začátek (15:00)</label>
        <input id="start" type="text" placeholder="Začátek (15:00)" v-model="reservation.start"/>

        <label for="end">Konec (17:00)</label>
        <input id="end" type="text" placeholder="Konec (17:00:00)" v-model="reservation.end"/>

        <label for="dayOfWeek">Den v tydnu</label>
        <select id="dayOfWeek" class="form-select" v-model="reservation.dayOfWeek">
            <option disabled value="">Vyber den nebo jednorazovou akci</option>
            <option value="X">Jednorázová akce</option>
            <option value="MONDAY">Pondělí</option>
            <option value="TUESDAY">Útery</option>
            <option value="WEDNESDAY">Středa</option>
            <option value="THURSDAY">Čtvrtek</option>
            <option value="FRIDAY">Pátek</option>
            <option value="SATURDAY">Sobota</option>
            <option value="SUNDAY">Neděle</option>
        </select>

        <label for="description">Popis</label>
        <textarea id="description" class="form-control" v-model="reservation.description" placeholder="Tenis/nohejbal"/>

        <input v-show="isAdmin" type="checkbox" class="btn-check" id="approved" role="switch" v-model="reservation.approved" :checked="reservation.approved" />
        <label v-show="isAdmin" class="btn" :class="reservation.approved ? 'btn-success' : 'btn-danger'" for="approved">{{ reservation.approved ? 'Schváleno' : 'Neschváleno' }}</label>
        <button class="btn btn-success" @click="createNewReservation" :disabled="creating">{{ creating ? 'Vytvářím...' : 'Vytvořit' }}</button>
        <div class="error">{{ error }}</div>
    </div>
</template>

<script>
import { computed, ref, watch } from 'vue';
import { user, isAdmin } from '../composables/useAuth'
import { useRouter } from "vue-router"
import { getSpaces } from '../composables/useSpaces'

export default {
    setup() {

        const router = useRouter()

        const error = ref(null);
        const creating = ref(false);
        const { spaces } = getSpaces();

        const reservation = ref({
            id: null,
            userId: user.value.id,
            spaceId: null,
            from: '2022-05-01',
            to: '2022-10-01',
            start: '15:00',
            end: '17:00',
            dayOfWeek: null,
            approved: false,
            description: null
        })

        function createNewReservation() {
            error.value = null
            console.log("Creating new reservation")
            creating.value = true
            if(reservation.value.dayOfWeek == 'X'){
                console.log("Changing dayOfWeek to null value due to one-time action")
                reservation.value.dayOfWeek = null
            }

            console.log(JSON.stringify(reservation.value))

            return fetch(process.env.VUE_APP_API_URL + "/users/" + reservation.value.userId + "/reservations", {
                method: "POST",
                mode: 'cors',
                headers: {
                    'Authorization': 'Bearer ' + user.value.accessToken,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(reservation.value)
            })
            .then(res => {
                console.log(res)
                if(res.ok){
                    router.push({ name: 'Home' })
                } else {
                    console.log("Promise.reject() call")
                    return Promise.reject(res)
                }
            })
            .catch(response => {
                error.value = 'Error status: ' + response.statusText + ' (' + response.status + ')'
                creating.value = false
            })
        }

    return { isAdmin, reservation, error, creating, createNewReservation, spaces }
    }
}
</script>

<style scoped>
.btn-check {
    position: fixed;
}
</style>