<template>
  <div class="container" style="padding: 10px">
      <h3><button class="btn btn-danger" @click="removeSpace"><i class="bi bi-trash"></i></button>
        {{ spaceDetail.spaceId }}</h3>
      <div v-if="!editMode">
        <br/>
        <table class="table">
            <tr>
                <td class="label">ID</td>
                <td>{{ spaceDetail.id }}</td>
            </tr>
            <tr>
                <td class="label">Interní ID</td>
                <td>{{ spaceDetail.spaceId }}</td>
            </tr>
            <tr>
                <td class="label">Cena za minutu</td>
                <td>{{ spaceDetail.pricePerMinute }}</td>
            </tr>
            <tr>
                <td class="label">Popis</td>
                <td>{{ spaceDetail.label }}</td>
            </tr>
            <tr>
                <td class="label">Aktivní</td>
                <td>
                    <input type="checkbox" id="paid" role="switch" v-model="spaceDetail.active" :checked="spaceDetail.active" disabled/>
                </td>
            </tr>
        </table>
        <br>
      </div>
      <!-- Editing mode below -->
      <div v-else>
        <br/>
        <table>
            <tr>
                <td class="label">ID</td>
                <td>{{ spaceDetail.id }}</td>
            </tr>
            <tr>
                <td class="label">Interní ID</td>
                <td>{{ spaceDetail.spaceId }}</td>
            </tr>
            <tr>
                <td class="label">Cena za minutu</td>
                <td>
                    <input type="text" placeholder="0.834" v-model="spaceDetail.pricePerMinute"/>
                </td>
            </tr>
            <tr>
                <td class="label">Popis</td>
                <td>
                    <input type="text" placeholder="Tenisový kurt 01" v-model="spaceDetail.label"/>
                </td>
            </tr>
            <tr>
                <td class="label">Aktivní</td>
                <td>
                    <input type="checkbox" id="paid" role="switch" v-model="spaceDetail.active" :checked="spaceDetail.active" />
                </td>
            </tr>
        </table>
        <br/>
      </div>
      <div class="error">{{ error }}</div>
      <button v-if="!editMode" @click="toggleEditMode" class="btn btn-warning">Edit</button>
      <button v-if="editMode" @click="saveSpace" class="btn btn-success" :disabled="creating">{{ creating ? 'Ukládám...' : 'Uložit' }}</button>
      <button v-if="editMode" @click="toggleEditMode" class="btn btn-light">Cancel</button>
  </div>

    <div class="container" style="padding: 10px">
        <PaginationList :items="doorEvents">
            <template #item="{ action, userId, created }">
                    <div role="alert" style="display: flex; align-items: center;, text-align: center; justify-content: space-between" class="alert" :class="action == 'login' ? 'alert-secondary' : action == 'open' ? 'alert-warning' : 'alert-success'">
                        <span>{{ userId }}</span>
                        <span><small>{{ created }}</small></span>
                        <span>{{ action }}</span>
                    </div>
            </template>
        </PaginationList>
    </div>
</template>

<script>
import { onMounted, ref } from "vue"
import { user } from "../composables/useAuth"
import { useRouter } from "vue-router"
import PaginationList from "../components/PaginationList.vue"

export default {
    props: ["id"],
    components: {PaginationList},
    setup(props) {

        const router = useRouter()

        const editMode = ref(false)
        const creating = ref(false)
        const error = ref('')

        const spaceDetail = ref({})
        const doorEvents = ref([])

        function toggleEditMode() {
            error.value = ''
            editMode.value = !editMode.value
        }

        function saveSpace() {
            error.value = ''
            editMode.value = false
            console.log("Saving modified space: " + JSON.stringify(spaceDetail.value))
            return fetch(process.env.VUE_APP_API_URL + "/spaces/" + spaceDetail.value.id, {
                method: "PUT",
                mode: 'cors',
                headers: {
                    'Authorization': 'Bearer ' + user.value.accessToken,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(spaceDetail.value)
            })
            .then(res => {
                console.log(res)
                if(res.ok){
                    router.push({ name: 'Home' })
                } else {
                    console.log("Promise.reject() call")
                    error.value = 'Error status: ' + response.statusText + ' (' + response.status + ')'
                    return Promise.reject(res)
                }
            })
            .catch(response => {
                error.value = 'Error status: ' + response.statusText + ' (' + response.status + ')'
                creating.value = false
            })
        }

        function removeSpace() {
            console.log("Removing space " + props.id)
            return fetch(process.env.VUE_APP_API_URL + "/spaces/" + props.id, {
                method: "DELETE",
                headers: {
                    'Authorization': 'Bearer ' + user.value.accessToken
                }
            })
            .then(res => {
                console.log(res)
                if(res.ok){
                    router.push({ name: 'Home' })
                } else {
                    console.log("Promise.reject() call")
                    return Promise.reject(res)
                }
            })
            .catch(response => {
                error.value = 'Error status: ' + response.statusText + ' (' + response.status + ')'
            })
        }
        

        function getSpaceDetail(id) {
            return fetch(process.env.VUE_APP_API_URL + "/spaces/" + id, {
                headers: {
                    'Authorization': 'Bearer ' + user.value.accessToken
                }
            })
            .then(res => {
                console.log(res)
                if(res.ok){
                    return res.json()
                } else {
                    console.log("Promise.reject() call")
                    return Promise.reject(res)
                }
            })
            .then(data => {
                console.log("Obtained user:")
                console.log(data)
                spaceDetail.value = data
                getSpaceDoorEvents(spaceDetail.value.spaceId)
            })
            .catch(response => {
                error.value = 'Error status: ' + response.statusText + ' (' + response.status + ')'
            })
        }

        function getSpaceDoorEvents(spaceId) {
            return fetch(process.env.VUE_APP_API_URL + "/doors/" + spaceId + "/events", {
                headers: {
                    'Authorization': 'Bearer ' + user.value.accessToken
                }
            })
            .then(res => {
                console.log(res)
                if(res.ok){
                    return res.json()
                } else {
                    console.log("Promise.reject() call")
                    return Promise.reject(res)
                }
            })
            .then(data => {
                console.log("Obtained door events:")
                console.log(data)
                doorEvents.value = data
            })
            .catch(response => {
                error.value = 'Error status: ' + response.statusText + ' (' + response.status + ')'
            })
        }

        onMounted(() => {
            console.log("Obtained space id from props: ")
            console.log(props.id)
            getSpaceDetail(props.id)
        })

        return { editMode, toggleEditMode, saveSpace, spaceDetail, removeSpace, error, creating, doorEvents }
    }
}
</script>

<style scoped>
.label {
    font-weight: bold;
    padding-right: 20px;
}
</style>