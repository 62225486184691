<template>
  <h2>Rezervace</h2>
  <h4>Dlouhodobé</h4>
  <!-- <div>
    <table class="table table-striped">
        <tr class="text-start">
            <td>Pondělí</td>
            <td></td>
        </tr>
        <tr class="text-start">
            <td>Úterý</td>
            <td>
                16:00-17:30 Potiorek<br/>
                17:30-19:00 Szkandera
            </td>
        </tr>
        <tr class="text-start">
            <td>Středa</td>
            <td>16-19 Machálek</td>
        </tr>
        <tr class="text-start">
            <td>Čtvrtek</td>
            <td>18-22 Swaczyna</td>
        </tr>
        <tr class="text-start">
            <td>Pátek</td>
            <td>
                15-18 Lokay<br/>
                18-19 Heczková
            </td>
        </tr>
        <tr class="text-start">
            <td>Sobota</td>
            <td></td>
        </tr>
        <tr class="text-start">
            <td>Neděle</td>
            <td></td>
        </tr>
    </table>
  </div>

  <div>
      <h4>Akce</h4>
      <span>21.5 - Klosko (13:00-17:00)</span>
      <br/>
      <span>27.8 - Tenisový turnaj (celý den)</span>
  </div> -->

  <div>
    <table class="table table-bordered">
        <tr class="text-start">
            <td>Pondělí</td>
            <td>
                <small v-for="p in reservations.filter(function(res) {return res.dayOfWeek == 'MONDAY'})" :key="p.id">
                {{ p.start.substring(0,5) }}-{{ p.end.substring(0,5) }} {{ p.userName }}<br/>
                </small>
            </td>
        </tr>
        <tr class="text-start">
            <td>Úterý</td>
            <td>
                <small v-for="p in reservations.filter(function(res) {return res.dayOfWeek == 'TUESDAY'})" :key="p.id">
                {{ p.start.substring(0,5) }}-{{ p.end.substring(0,5) }} {{ p.userName }}<br/>
                </small>
            </td>
        </tr>
        <tr class="text-start">
            <td>Středa</td>
            <td>
                <small v-for="p in reservations.filter(function(res) {return res.dayOfWeek == 'WEDNESDAY'})" :key="p.id">
                {{ p.prettyStart}}-{{ p.prettyEnd }} {{ p.userName }}<br/>
                </small>
            </td>
        </tr>
        <tr class="text-start">
            <td>Čtvrtek</td>
            <td>
                <small v-for="p in reservations.filter(function(res) {return res.dayOfWeek == 'THURSDAY'})" :key="p.id">
                {{ p.start.substring(0,5) }}-{{ p.end.substring(0,5) }} {{ p.userName }}<br/>
                </small>
            </td>
        </tr>
        <tr class="text-start">
            <td>Pátek</td>
            <td>
                <small v-for="p in reservations.filter(function(res) {return res.dayOfWeek == 'FRIDAY'})" :key="p.id">
                {{ p.start.substring(0,5) }}-{{ p.end.substring(0,5) }} {{ p.userName }}<br/>
                </small>
            </td>
        </tr>
        <tr class="text-start">
            <td>Sobota</td>
            <td>
                <small v-for="p in reservations.filter(function(res) {return res.dayOfWeek == 'SATURDAY'})" :key="p.id">
                {{ p.start.substring(0,5) }}-{{ p.end.substring(0,5) }} {{ p.userName }}<br/>
                </small>
            </td>
        </tr>
        <tr class="text-start">
            <td>Neděle</td>
            <td>
                <small v-for="p in reservations.filter(function(res) {return res.dayOfWeek == 'SUNDAY'})" :key="p.id">
                {{ p.start.substring(0,5) }}-{{ p.end.substring(0,5) }} {{ p.userName }}<br/>
                </small>
            </td>
        </tr>
    </table>
  </div>

  <div>
      <h4>Akce</h4>
      <table class="table table-striped">
          <tr class="text-start" v-for="akce in reservations.filter(function(res) { return res.dayOfWeek == null })" :key="akce.id">
              <td>{{ akce.from }} - {{ akce.to }} ({{ akce.start.substring(0,5) }}-{{ akce.end.substring(0,5)}}) {{ akce.userName }} ({{ akce.description }})<br/><br/></td>
          </tr>
      </table>
      <!-- <span>21.5 - Klosko (13:00-17:00)</span>
      <br/>
      <span>27.8 - Tenisový turnaj (celý den)</span> -->
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { getAllReservations } from '../composables/getAllReservations';

export default {
    setup() {

        const { reservations: rawReservations, perpetual, onetime, error } = getAllReservations()

        const reservations = computed(() => {
                //     let prettyEvents = rawSpaceEvents.value.map(event => {
                //     let onlyDate = getOnlyDate(event.start)
                //     let prettyStart = getPrettyTime(event.start)
                //     let prettyEnd = getPrettyTime(event.end)
                //     let duration = durationInMinutes(event.start, event.end)
                //     return { ...event, onlyDate: onlyDate, prettyStart: prettyStart, prettyEnd: prettyEnd, duration: duration }
                // })
                let approvedReservations = rawReservations.value.filter(function(r) {
                    let today = Date.now()
                    let from = r.from + 'T' + r.start + 'Z'
                    let to = r.to + 'T' + r.end + 'Z'
                    if(new Date(Date.now()).toISOString() < to && r.approved == true){
                        return r;
                    }
                })

                let sortedReservations = approvedReservations.sort(function(x,y) {
                    let today = Date.now()
                    let startx = new Date(new Date(today).toISOString().substring(0,10) + 'T' + x.start)
                    let starty = new Date(new Date(today).toISOString().substring(0,10) + 'T' + y.start)
                    return startx-starty
                })

                let prettyReservations = sortedReservations.map(res => {
                    return { ...res, prettyStart: makePrettyTime(res.start), prettyEnd: makePrettyTime(res.end)}
                })

                return prettyReservations
        })

        function makePrettyTime(time) {
            return time.substring(0,5)
        }

        return { reservations, perpetual, onetime, error } 
    },
}
</script>

<style scoped>
div {
    width: 300px;
    margin: 20px auto;
}
</style>