import { user } from "./useAuth"
import { ref, computed } from "vue"

function translateDay(lang, day) {
    if(lang == 'cz' || lang == 'CZ') {
        console.log("Prekladam do cestiny")
        switch (day) {
            case 'MONDAY':
                return 'Po'
            case 'TUESDAY':
                return 'Út'
            case 'WEDNESDAY':
                return 'St'
            case 'THURSDAY':
                return 'Čt'
            case 'FRIDAY':
                return 'Pá'
            case 'SATURDAY':
                return 'So'
            case 'SUNDAY':
                return 'Ne'
        }
    }

    return day
}

function getReservationDetail(userId, reservationId) {
    console.log("Fetching reservation detail " + reservationId)
    const reservationDetail = ref({})

    try {
        fetch(process.env.VUE_APP_API_URL + "/users/" + userId + "/reservations/" + reservationId, {
            headers: {
                'Authorization': 'Bearer ' + user.value.accessToken
            }
        })
        .then(res => {
            console.log(res)
            if(res.ok){
                return res.json()
            } else {
                console.log("Promise.reject() call")
                return Promise.reject(res)
            }
        })
        .then(data => {
            console.log(data)
            reservationDetail.value = data
        })
        .catch(response => {
            console.log('Error status: ' + response.statusText + ' (' + response.status + ')')
        })
    } catch (error) {
        console.log(error)
    }

    return { reservationDetail }
}


export { translateDay, getReservationDetail }