import { ref } from "vue"
import { user } from "./useAuth"

export function getCurrentUser() {
    const error = ref(null);
    const currentUser = ref(null);

    try {
        fetch(process.env.VUE_APP_API_URL + "/profile", {
            headers: {
                'Authorization': 'Bearer ' + user.value.accessToken
            }
        })
        .then(res => {
            console.log(res)
            if(res.ok){
                return res.json()
            } else {
                console.log("Promise.reject() call")
                return Promise.reject(res)
            }
        })
        .then(data => {
            console.log(data)
            console.log("Assigning data to currentUser")
            currentUser.value = data
            console.log("Assigned data to currentUser")
            console.log(data)
            return data;
        })
        .catch(response => {
            error.value = 'Error status: ' + response.statusText + ' (' + response.status + ')'
        })
    } catch (error) {
        console.log(error)
        error.value = error
    }

    return { currentUser, error }
}