<template>
  <div class="welcome container">
      <p>Dveřník 3000</p>
      <div v-if="showLogin">
          <h2>Přihlášení</h2>
          <LoginForm @login="enterHome" />
          <p>Nemáš účet? <span @click="showLogin = false">Zaregistovat</span> se</p>
      </div>
      <div v-else>
          <h2>Registrace</h2>
          <SignupForm @signup="enterHome" />
          <p>Už máš účet? <span @click="showLogin = true">Přihlásit</span> se</p>
      </div>
      <br/>
      <div class="container weather">
        <a class="weatherwidget-io" href="https://forecast7.com/cs/49d6918d57/739-59/" data-label_1="Střítež" data-label_2="počasí" data-days="3" data-mode="Current" >Střítež počasí</a>
      </div>
  </div>
  <div class="welcome container">
    <ReservationOverview />
  </div>
  <div class="welcome container">
    <HowTo/>
  </div>
</template>

<script>
!function(d,s,id){var js,fjs=d.getElementsByTagName(s)[0];if(!d.getElementById(id)){js=d.createElement(s);js.id=id;js.src='https://weatherwidget.io/js/widget.min.js';fjs.parentNode.insertBefore(js,fjs);}}(document,'script','weatherwidget-io-js');
import SignupForm from "../components/SignupForm.vue"
import LoginForm from "../components/LoginForm.vue"
import ReservationOverview from "../components/ReservationOverview.vue"
import HowTo from "../components/HowTo.vue"
import { ref } from "vue"
import { useRouter } from "vue-router"

export default {
    components: { SignupForm, LoginForm, ReservationOverview, HowTo },
    setup() {
        const showLogin = ref(true)
        const router = useRouter()

        const enterHome = () => {
            router.push({ name: 'Home' })
        }

        return { showLogin, enterHome }
    }
}
</script>

<style>
  .welcome {
    text-align: center;
    padding: 20px 0;
  }
  /* form styles */
  .welcome form {
    width: 300px;
    margin: 20px auto;
  }
  .welcome label {
    display: block;
    margin: 20px 0 10px;
  }
  .welcome input {
    width: 100%;
    padding: 10px;
    border-radius: 20px;
    border: 1px solid #eee;
    outline: none;
    color: #999;
    margin: 10px auto;
  }
  .welcome span {
      font-weight: bold;
      text-decoration: underline;
      cursor: pointer;
  }
  .welcome button {
      margin: 20px auto;
  }
  .weather {
    margin: auto !important;
    max-width: 300px !important;
  }
</style>