<template>
    <UserProfile/>
    <Reservations :userId="user.id"/>
    <SpaceEvents/>
    <div v-if="!isAdmin">
      <Report/>
    </div>
    <div v-if="isAdmin">
      <AllUsers/>
      <AllSpaces/>
      <AdminActions/>
    </div>
</template>

<script>
import SignupForm from "../components/SignupForm.vue"
import LoginForm from "../components/LoginForm.vue"
import { computed, onUpdated, ref } from "vue"
import { useRouter } from "vue-router"
// import getUser from "../composables/getUser"
import { user, isAdmin } from "../composables/useAuth"
import UserProfile from "../components/UserProfile.vue"
import AdminActions from "../components/AdminActions.vue"
import AllUsers from "../components/AllUsers.vue"
import SpaceEvents from "./SpaceEvents.vue"
import AllSpaces from "../components/AllSpaces.vue"
import Reservations from "../views/Reservations.vue"
import Report from "../components/Report.vue"

export default {
  components: { UserProfile, AllUsers, AllSpaces, SpaceEvents, AdminActions, Report, Reservations },
  // setup() {

  //   const { error, currentUser } = getUser()

  //   console.log("Inside")

  //   // const gUser = computed(() => {
  //   //   if(currentUser.value){
  //   //     console.log("Received value")
  //   //   }
  //   // })

  //   // onUpdated(() => {
  //   //   console.log("Updated")
  //   // })

  //   return { error, currentUser}
  // }
  setup() {
    return { user, isAdmin }
  }
}
</script>

<style>
  .welcome {
    text-align: center;
    padding: 20px 0;
  }
  /* form styles */
  .welcome form {
    width: 300px;
    margin: 20px auto;
  }
  .welcome label {
    display: block;
    margin: 20px 0 10px;
  }
  .welcome input {
    width: 100%;
    padding: 10px;
    border-radius: 20px;
    border: 1px solid #eee;
    outline: none;
    color: #999;
    margin: 10px auto;
  }
  .welcome span {
      font-weight: bold;
      text-decoration: underline;
      cursor: pointer;
  }
  .welcome button {
      margin: 20px auto;
      cursor: pointer;
  }
</style>