import { ref } from "vue"

const error = ref(null)

const signup = async (email, password, displayName, phoneNumber) => {
    error.value = null

    try {
        await fetch(process.env.VUE_APP_API_URL + "/auth/register", {
            method: "POST",
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({'email':email,'password':password,'phoneNumber':phoneNumber,'username':displayName})
        })
        .then(res => {
            console.log(res)
            if(res.ok){
                return res.json()
            } else {
                console.log("Promise.reject() call")
                return Promise.reject(res)
            }
        })
        .then(data => {
            console.log("Successfully registered user")
            console.log(data)
            return data;
        })
        .catch(response => {
            error.value = 'Error status: ' + response.statusText + ' (' + response.status + ')'
        })
    } catch (error) {
        console.log("Register error: ", error)
        error.value = error
    }
}

const useSignup = () => {
    return { error, signup }
}

export default useSignup