<template>
  <h2>Návod</h2>
  <br/>
  <h4>Začátek akce</h4>

  <div class="steps">
    <ol>
        <li>Aktivujte stisknutím libovolné klávesy</li>
        <li>Zadejte uživatelské ID a PIN (naleznete po přihlášení)</li>
        <li>Zmáčkněte "1" pro příchod</li>
        <li>Postupujte podle instrukcí na displeji</li>
        <li>Odhlaste se !! (zmáčkněte "0")</li>
    </ol>
  </div>

  <h4>Ukončení akce</h4>
  <div class="steps">
    <ol>
        <li>Aktivujte stisknutím libovolné klávesy</li>
        <li>Zadejte uživatelské ID a PIN (naleznete po přihlášení)</li>
        <li>Zmáčkněte "2" pro odchod</li>
        <li>Postupujte podle instrukcí na displeji</li>
        <li>Odhlaste se !! (zmáčkněte "0")</li>
    </ol>
  </div>
  <div>
      <hr/>
      V případě potíží volejte <br/> 
      +420 728 315 707 (Jan Kadlec)
  </div>

</template>

<style scoped>
div {
    width: 300px;
    margin: 20px auto;
}
.steps {
    text-align: left;
}
</style>
