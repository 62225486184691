<template>
  <div class="container" style="padding: 10px">
      <h3 class="p-3 text-center">Historie <i class="bi bi-arrow-clockwise btn" v-on:click="fetchSpaceEvents"></i></h3>

      <div v-if="isAdmin">
        <button v-show="showAllEvents" role="button" class="btn btn-secondary btn-sm rounded-pill" @click="toggleOwnOrAllEvents"><i class="bi bi-eye"></i>Zobrazit pouze vlastní</button>
        <button v-show="!showAllEvents" role="button" class="btn btn-secondary btn-sm rounded-pill" @click="toggleOwnOrAllEvents"><i class="bi bi-eye-fill"></i> Zobrazit všechny</button>
        <br/>
        <br/>
      </div>

      <div v-if="spaceEvents.length">

      <PaginationList :items="spaceEvents">
        <template #item="{ paid, id, onlyDate, duration, price}">
            <div class="event p-3 text-center" :class="paid ? 'paid' : 'unpaid'">
                <router-link :to="{ name: 'EventDetail', params: { id: id}}" class="date">{{ onlyDate }}</router-link>
                <span>{{ duration }}m</span>
                <span class="price badge rounderd-pill bg-light">{{ price }},- Kč</span>
            </div>
        </template>
      </PaginationList>

        <div v-if="showAllEvents">
            <hr/>
            <h4 style="text-align: center;">Celkem</h4>
            <div style="justify-content: space-between; align-items: center; display: flex;">
                <span class="badge bg-success rounded-pill">Zaplaceno: {{ paidTotal }}</span>
                <span class="badge bg-danger rounded-pill">Nezaplaceno: {{ unpaidTotal }}</span>
            </div>
        </div>

      </div>
      <div v-else>
          Nenalezeny žádné záznamy
      </div>
      
  </div>
</template>

<script>
import { onMounted, ref, computed} from "vue"
import { user, isAdmin} from "../composables/useAuth"
import { getCurrentUser } from "../composables/getCurrentUser"
import { useRouter } from "vue-router"
import PaginationList from '../components/PaginationList.vue'

export default {
    components: {PaginationList},
    setup() {

        const showAllEvents = ref(true)
        const rawSpaceEvents = ref([])

        const { currentUser, error } = getCurrentUser();

        function fetchSpaceEvents() {
            console.log("Fetching space events")
            return fetch(process.env.VUE_APP_API_URL + "/events", {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + user.value.accessToken
                }
            })
            .then(res => {
                console.log(res)
                if(res.ok){
                    return res.json()
                } else {
                    console.log("Promise.reject() call")
                    return Promise.reject(res)
                }
            }).then(data => {
                rawSpaceEvents.value = data;
            })
            .catch(response => {
                error.value = 'Error status: ' + response.statusText + ' (' + response.status + ')'
            })
        }

        function toggleOwnOrAllEvents() {
            showAllEvents.value = !showAllEvents.value
        }

        function getOnlyDate(timestamp) {
            var date = new Date(timestamp)
            return date.getFullYear() + "-" + (date.getMonth() + 1).toString().padStart(2, "0") + "-" + date.getDate().toString().padStart(2, "0")
        }

        function getPrettyTime(timestamp) {
            var date = new Date(timestamp)
            return ("0" + date.getHours()).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2) + ":" + ("0" + date.getSeconds()).slice(-2)
        }

        function durationInMinutes(start, end) {
            if(end == null){
                return "Probíhá"
            }
            var diff = Math.abs(new Date(end) - new Date(start));
            var minutes = Math.floor((diff/1000)/60);
            return minutes
        }

        const spaceEvents = computed(() => {
            if(rawSpaceEvents.value){
                let prettyEvents = rawSpaceEvents.value.map(event => {
                    let onlyDate = getOnlyDate(event.start)
                    let prettyStart = getPrettyTime(event.start)
                    let prettyEnd = getPrettyTime(event.end)
                    let duration = durationInMinutes(event.start, event.end)
                    return { ...event, onlyDate: onlyDate, prettyStart: prettyStart, prettyEnd: prettyEnd, duration: duration }
                })

                if(showAllEvents.value) {
                    return prettyEvents;
                }

                return prettyEvents.filter(function(e) {
                    return e.userId == currentUser.value.userId
                });
            }
        })

        const paidTotal = computed(() => {
            let sum = 0;
            rawSpaceEvents.value.forEach(element => {
               if(element.paid == true) {
                    sum += element.price
               }
            });

            return sum
        })

        const unpaidTotal = computed(() => {
            let sum = 0;
            rawSpaceEvents.value.forEach(element => {
               if(element.paid != true) {
                    sum += element.price
               }
            });

            return sum
        })

        onMounted(() => {
            fetchSpaceEvents()
        })

        return { isAdmin, showAllEvents, spaceEvents, toggleOwnOrAllEvents, fetchSpaceEvents, paidTotal, unpaidTotal }
    }
}
</script>

<style scoped>
.event {
    margin-bottom: 20px;
    border-radius: 25px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.date {
    font-weight: bold;
    color: blue;
}
.paid {
    background: rgb(167, 196, 167);
    color: white;
}
.unpaid {
    background: mistyrose;
    color: black;
}
.price {
    font-size: large;
    font-weight: bold;
    color: black;
}
</style>