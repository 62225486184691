<template>
  <div class="container" style="padding: 10px">
      <h3 class="p-3 text-center">Uživatelé</h3>
      <table class="table table-bordered">
          <thead>
              <tr>
                  <td>Jméno</td>
                  <td>Email</td>
              </tr>
          </thead>
          <tbody>
              <tr v-for="user in users" :key="user.id" :class="`${!user.active ? 'inactive' : ''}`">
                  <td><span v-if="user.roles.includes('ADMIN')" class="badge bg-success">ADMIN</span> {{ user.username }}</td>
                  <td><router-link :to="{ name: 'UserDetail', params: { id: user.id}}" >
                    {{ user.email }}
                  </router-link></td>
              </tr>
          </tbody>
      </table>
  </div>
</template>

<script>
import { onMounted } from '@vue/runtime-core';
import { ref } from 'vue';
import { user, isAdmin } from '../composables/useAuth'
// import getUser from "../composables/getUser"

export default {
    setup() {

        const error = ref(null);
        const users = ref([]);

        function getUsers() {
            return fetch(process.env.VUE_APP_API_URL + "/users", {
                headers: {
                    'Authorization': 'Bearer ' + user.value.accessToken
                }
            })
            .then(res => {
                console.log(res)
                if(res.ok){
                    return res.json()
                } else {
                    console.log("Promise.reject() call")
                    return Promise.reject(res)
                }
            })
            .then(data => {
                console.log(data)
                users.value = data
            })
            .catch(response => {
                error.value = 'Error status: ' + response.statusText + ' (' + response.status + ')'
            })
        }

        onMounted(() => {
            if(isAdmin.value) {
                getUsers()
            }
        })

        return { error, users }
    }
}
</script>

<style scoped>
.inactive {
    color: white;
    background: gray;
}
</style>