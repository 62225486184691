import { ref, computed } from "vue"
import { user } from "./useAuth"

export function getUserReservations(id) {
    const error = ref(null);
    const userReservations = ref([]);

    try {
        fetch(process.env.VUE_APP_API_URL + "/users/" + id + "/reservations", {
            headers: {
                'Authorization': 'Bearer ' + user.value.accessToken
            }
        })
        .then(res => {
            console.log(res)
            if(res.ok){
                return res.json()
            } else {
                console.log("Promise.reject() call")
                return Promise.reject(res)
            }
        })
        .then(data => {
            // console.log(data)
            // console.log("Assigning data to userReservations")
            // console.log("Obtained data: " + JSON.stringify(data))
            // console.log(data.length)
            for (let i = 0; i < data.length; i++) {
                // console.log("Iteruji pres data")
                data[i].expired = isExpired(data[i].to)
                // console.log("Zapasano do expired")
            }
            userReservations.value = data
            // console.log("Assigned data to userReservations")
            // console.log(data)
        })
        .catch(response => {
            error.value = 'Error status: ' + response.statusText + ' (' + response.status + ')'
        })
    } catch (error) {
        console.log(error)
        error.value = error
    }

    const perpetual = computed(() => {
        return userReservations.value.filter(function (res) {
            return res.dayOfWeek != null
        })
    })

    const onetime = computed(() => {
        return userReservations.value.filter(function (res) {
            return res.dayOfWeek == null
        })
    })

    function isExpired(date) {
        // console.log("Prislo datum: " + date)
        // console.log("Datum mam")
        let today = Date.now()
        // console.log("today: " + today)
        let dateToCompare = Date.parse(date)
        // console.log("today: " + today + " - dateToCompare: " + dateToCompare)

        return today > dateToCompare ? true : false
    }

    return { userReservations, perpetual, onetime, error }
}