<template>
  <div class="container" style="padding: 10px">
      <h3><button class="btn btn-danger" @click="removeUser"><i class="bi bi-trash"></i></button>
        {{ userDetail.username }}</h3>
      <br/>
      <table>
          <tr>
              <td class="label">ID</td>
              <td>{{ userDetail.id }}</td>
          </tr>
          <tr>
              <td class="label">Email</td>
              <td>{{ userDetail.email }}</td>
          </tr>
          <tr>
              <td class="label">Telefon</td>
              <td>{{ userDetail.phoneNumber }}</td>
          </tr>
          <tr>
              <td class="label">ID</td>
              <td>{{ userDetail.userId }}</td>
          </tr>
          <tr>
              <td class="label">PIN</td>
              <td>{{ userDetail.pin }}</td>
          </tr>
          <tr>
              <td class="label">Role</td>
              <td>{{ userDetail.roles }}
                  <button class="btn btn-sm" :class="userDetail.roles.includes('ADMIN') == true ? 'btn-outline-danger' : 'btn-outline-success'" v-on:click="changeRoles">{{ userDetail.roles.includes('ADMIN') ? '-' : '+'}} ADMIN</button>
              </td>
          </tr>
      </table>
      <br>
      <button v-if="!userDetail.active" class="btn btn-success" @click="activateUser">Aktivovat</button>
      <button v-else class="btn btn-danger" @click="deactivateUser">Deaktivovat</button>
      
  </div>
  <Reservations :userId="userId"/>
  <SpaceEvents :events="userEvents" :userDetail="userDetail"/>
</template>

<script>
import { onMounted, ref } from "vue"
import { user } from "../composables/useAuth"
import SpaceEvents from "../components/SpaceEvents.vue"
import Reservations from "../views/Reservations.vue"
import { getUserEvents } from "../composables/getUserEvents"
import { useRouter } from "vue-router"

export default {
    components: { SpaceEvents, Reservations },
    props: ["id"],
    setup(props) {

        const router = useRouter()

        const userId = ref(props.id)

        const userDetail = ref({id: props.id, username: 'Test', email: 'test@test.com', roles: []})

        const { userEvents, error } = getUserEvents(props.id)

        console.log("userEvents = " + userEvents.value)

        function removeUser() {
            console.log("Removing user " + props.id)
            return fetch(process.env.VUE_APP_API_URL + "/users/" + props.id, {
                method: "DELETE",
                headers: {
                    'Authorization': 'Bearer ' + user.value.accessToken
                }
            })
            .then(res => {
                console.log(res)
                if(res.ok){
                    router.push({ name: 'Home' })
                } else {
                    console.log("Promise.reject() call")
                    return Promise.reject(res)
                }
            })
            .catch(response => {
                error.value = 'Error status: ' + response.statusText + ' (' + response.status + ')'
            })
        }
        

        function activateUser() {
            console.log("Activating user " + props.id)
            return fetch(process.env.VUE_APP_API_URL + "/users/" + props.id + "/activate", {
                method: "POST",
                headers: {
                    'Authorization': 'Bearer ' + user.value.accessToken
                }
            })
            .then(res => {
                console.log(res)
                if(res.ok){
                    return res.json()
                } else {
                    console.log("Promise.reject() call")
                    return Promise.reject(res)
                }
            })
            .then(data => {
                console.log("Obtained user:")
                console.log(data)
                userDetail.value = data
            })
            .catch(response => {
                error.value = 'Error status: ' + response.statusText + ' (' + response.status + ')'
            })
        }

        function deactivateUser() {
            console.log("Deactivating user " + props.id)
            return fetch(process.env.VUE_APP_API_URL + "/users/" + props.id + "/deactivate", {
                method: "POST",
                headers: {
                    'Authorization': 'Bearer ' + user.value.accessToken
                }
            })
            .then(res => {
                console.log(res)
                if(res.ok){
                    return res.json()
                } else {
                    console.log("Promise.reject() call")
                    return Promise.reject(res)
                }
            })
            .then(data => {
                console.log("Obtained user:")
                console.log(data)
                userDetail.value = data
            })
            .catch(response => {
                error.value = 'Error status: ' + response.statusText + ' (' + response.status + ')'
            })
        }

        function getUserDetail(id) {
            return fetch(process.env.VUE_APP_API_URL + "/users/" + id, {
                headers: {
                    'Authorization': 'Bearer ' + user.value.accessToken
                }
            })
            .then(res => {
                console.log(res)
                if(res.ok){
                    return res.json()
                } else {
                    console.log("Promise.reject() call")
                    return Promise.reject(res)
                }
            })
            .then(data => {
                console.log("Obtained user:")
                console.log(data)
                userDetail.value = data
            })
            .catch(response => {
                error.value = 'Error status: ' + response.statusText + ' (' + response.status + ')'
            })
        }

        function changeRoles() {
            console.log("Changing user roles " + props.id)

            let roles = userDetail.value.roles;

            if(roles.includes('ADMIN')){
                roles = roles.filter(role => !role.includes('ADMIN'))
            } else {
                roles.push('ADMIN')
            }

            console.log("New roles: " + roles);

            return fetch(process.env.VUE_APP_API_URL + "/users/" + props.id + "/roles", {
                method: "POST",
                headers: {
                    'Authorization': 'Bearer ' + user.value.accessToken,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(roles)
            })
            .then(res => {
                console.log(res)
                if(res.ok){
                    return res.json()
                } else {
                    console.log("Promise.reject() call")
                    return Promise.reject(res)
                }
            })
            .then(data => {
                console.log("Obtained user:")
                console.log(data)
                userDetail.value = data
            })
            .catch(response => {
                error.value = 'Error status: ' + response.statusText + ' (' + response.status + ')'
            })
        }

        onMounted(() => {
            console.log("Obtained user id from props: ")
            console.log(props.id)
            getUserDetail(props.id)
        })

        return { userId, userDetail, activateUser, deactivateUser, removeUser, changeRoles, userEvents }
    }
}
</script>

<style scoped>
.label {
    font-weight: bold;
    padding-right: 20px;
}
</style>