<template>
  <div class="container" style="padding: 10px">
      <h3 class="p-3 text-center">Rezervace <router-link class="brn btn-outline-success" :to="{ name: 'NewReservation' }"><i class="bi bi-calendar-plus btn-outline-success btn btn-sm"></i></router-link></h3>

      <h4 class="p-3 text-center">Jednorázové</h4>
      <div v-if="onetime.length">
        <div v-for="ot in onetime" :key="ot.id">
            <div class="p-3 text-right">
                <span><router-link class="btn btn-outline-primary btn-sm" :to="{ name: 'ReservationDetail', params: { id: ot.id, userId: userId } }"><i class="bi bi-info-circle"></i></router-link></span>
                <span v-if="ot.expired">&nbsp;<i class="bi btn-danger btn-sm">Platnost vypršela</i></span>
                <span v-if="!ot.approved">&nbsp;<i class="bi bi-hourglass-split btn-warning btn-sm">Čeká na schválení</i></span>
                <span>&nbsp;{{ ot.from }} - {{ ot.to }} ({{ot.start.substring(0,5)}} - {{ot.end.substring(0,5)}})</span>
                <span>&nbsp;{{ ot.description }}</span>
            </div>
        </div>
      </div>
      <div v-else>
          Nenalezeny žádné jednorázové akce
      </div>

      <h4 class="p-3 text-center">Dlouhodobé</h4>
      <div v-if="perpetual.length">
        <div v-for="perp in perpetual" :key="perp.id">
            <div class="p-3 text-right">
                <span><router-link class="btn btn-outline-primary btn-sm" :to="{ name: 'ReservationDetail', params: { id: perp.id, userId: userId } }"><i class="bi bi-info-circle"></i></router-link></span>
                <span v-if="perp.expired">&nbsp;<i class="bi btn-danger btn-sm">Platnost vypršela</i></span>
                <span v-if="!perp.approved">&nbsp;<i class="bi bi-hourglass-split btn-warning btn-sm">Čeká na schválení</i></span>
                <span>&nbsp;{{ perp.start }} - {{ perp.end }}</span>
                <span>&nbsp;({{ translateDay('cz', perp.dayOfWeek) }})&nbsp;</span>
                <span>{{ perp.description }}</span>
            </div>
        </div>
      </div>
      <div v-else>
          Nenalezeny žádné dlouhodobé akce
      </div>
  </div>
</template>

<script>
import { onMounted, ref, toRefs, computed, watch} from "vue"
import { user, isAdmin} from "../composables/useAuth"
import { getUserReservations } from "../composables/getUserReservations"
import { getCurrentUser } from "../composables/getCurrentUser"
import { useRouter } from "vue-router"
import { translateDay, deleteReservation } from "../composables/reservationsHelper"

export default {
    props: {
        userId: String
    },
    setup(props) {

        const userId = ref(props.userId)
        const { currentUser, error: currentUserError} = getCurrentUser();
        const { userReservations, perpetual, onetime, error: reservationError } = getUserReservations(props.userId);

        return { userId, perpetual, onetime, translateDay}
    }
}
</script>

<style scoped>
.event {
    margin-bottom: 20px;
    border-radius: 25px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.date {
    font-weight: bold;
    color: blue;
}
.approved {
    background: rgb(167, 196, 167);
    color: white;
}
.unapproved {
    background: mistyrose;
    color: black;
}
</style>