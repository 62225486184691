<template>
    <div class="container" style="padding: 10px">
        <h3 class="p-3 text-center">Hlášení problémů - dotazy</h3>
        <textarea class="form-control" v-model="text" placeholder="Sem napište problém nebo dotaz"/>
        <br/>
        <label v-if="responseOK" class="btn-outline-success">Email byl úspěšně odeslán</label>
        <label v-if="responseERROR" class="btn-outline-danger">ERROR: {{ responseERROR }}</label>
        <button class="btn btn-success" @click="handleSubmit" :disabled="disabled">{{ creating ? 'Odesílám' : 'Odeslat'}}</button>
    </div>
</template>

<script>
import { user } from '../composables/useAuth'
import { ref, computed } from 'vue';
export default {
    setup() {


        const responseOK = ref(false);
        const responseERROR = ref(null);
        const text = ref("");
        const creating = ref(false);

        const disabled = computed(() => {
            if(text.value.length > 0) {
                return false
            }

            return true
        })

        function handleSubmit() {
            console.log("Text: " + text.value)
            console.log("responseOK: " + responseOK.value)
            console.log("responseERROR: " + responseERROR.value)
            responseERROR.value = null;
            responseOK.value = false;
            creating.value = true;

            return fetch(process.env.VUE_APP_API_URL + "/report/problem", {
                method: "POST",
                mode: 'cors',
                headers: {
                    'Authorization': 'Bearer ' + user.value.accessToken,
                    'Content-Type': 'text/plain;charset=UTF-8'
                },
                body: text.value
            })
            .then(res => {
                console.log(res)
                if(res.ok){
                    responseOK.value = true
                    creating.value = false
                    text.value = "";
                } else {
                    console.log("Promise.reject() call")
                    responseERROR.value = "Něco se pokazilo ..."
                    return Promise.reject(res)
                }
            })
            .catch(response => {
                responseERROR.value = 'Error status: ' + response.statusText + ' (' + response.status + ')'
                creating.value = false
            })
        }

        return {handleSubmit, responseERROR, responseOK, text, disabled, creating}
    },
}
</script>

<style scoped>
.btn {
    width: -webkit-fill-available;
}
</style>