import { createRouter, createWebHistory } from 'vue-router'
import Welcome from '../views/Welcome.vue'
import Dashboard from '../views/Dashboard.vue'
import Home from '../views/Home.vue'
import { user } from '../composables/useAuth'
import UserDetail from '../views/UserDetail.vue'
import SpaceDetail from '../views/SpaceDetail.vue'
import EventDetail from '../views/EventDetail.vue'
import NewSpaceEvent from '../views/NewSpaceEvent.vue'
import ReservationDetail from '../components/ReservationDetail.vue'
import NewReservation from '../views/NewReservation.vue'

// auth guard
const requiredAuth = (to, from, next) => {
  let currentUser = user.value
  // console.log("Current user: ", currentUser)
  if(!currentUser) {
    next({ name: 'Welcome' })
  } else {
    next()
  }
}

const requireNoAuth = (to, from, next) => {
  localStorage.removeItem('token')
  // console.log("Current user: ", currentUser)
  let currentUser = user.value
  if(currentUser) {
    console.log("User existuje - forwarduji na home")
    next({ name: 'Home' })
  } else {
    console.log("User neexistuje - dalsi")
    next()
  }
}

const routes = [
  {
    path: '/welcome',
    name: 'Welcome',
    component: Welcome,
    beforeEnter: requireNoAuth
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: requiredAuth
  },
  {
    path: '/users/:id',
    name: 'UserDetail',
    component: UserDetail,
    props: true,
    beforeEnter: requiredAuth
  },
  {
    path: '/spaces/:id',
    name: 'SpaceDetail',
    component: SpaceDetail,
    props: true,
    beforeEnter: requiredAuth
  },
  {
    path: '/events/:id',
    name: 'EventDetail',
    component: EventDetail,
    props: true,
    beforeEnter: requiredAuth
  },
  {
    path: '/events/new',
    name: 'NewSpaceEvent',
    component: NewSpaceEvent,
    props: false,
    beforeEnter: requiredAuth
  },
  {
    path: '/reservations/:id',
    name: 'ReservationDetail',
    component: ReservationDetail,
    props: true,
    beforeEnter: requiredAuth
  },
  {
    path: '/reservations/new',
    name: 'NewReservation',
    component: NewReservation,
    props: false,
    beforeEnter: requiredAuth
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  }
})

export default router
