<template>
  <div class="container" style="padding: 10px">
    <div v-if="reservationDetail != null">
      <button class="btn btn-danger" @click="removeReservation(reservationDetail.id)"><i class="bi bi-trash"></i></button>
      <table>
          <tr>
              <td class="label">ID</td>
              <td>{{ reservationDetail.id }}</td>
          </tr>
          <tr>
              <td class="label">Prostor</td>
              <td>{{ getSpaceNameById(reservationDetail.spaceId)[0]?.spaceId }}</td>
          </tr>
          <tr>
              <td class="label">Datum od</td>
              <td>{{ reservationDetail.from }}</td>
          </tr>
          <tr>
              <td class="label">Datum do</td>
              <td>{{ reservationDetail.to }}</td>
          </tr>
          <tr>
              <td class="label">Začátek</td>
              <td>{{ reservationDetail.start }}</td>
          </tr>
          <tr>
              <td class="label">Konec</td>
              <td>{{ reservationDetail.end }}</td>
          </tr>
          <tr>
              <td class="label">Popis</td>
              <td>{{ reservationDetail.description}}</td>
          </tr>
          <tr>
              <td class="label">Schváleno</td>
              <td>{{ reservationDetail.approved ? 'ANO' : 'NE' }}
              <button v-show="isAdmin && !reservationDetail.approved" @click="approveReservation" class="btn btn-success btn-sm"><i class="bi bi-check"></i></button>
              </td>
          </tr>
      </table>
      <br>
      </div>
      <div v-else>
          <p>Loading...</p>
      </div>
  </div>
</template>

<script>
import { onMounted, ref, unref } from "vue"
import { user, isAdmin } from "../composables/useAuth"
import { getReservationDetail } from "../composables/reservationsHelper"
import { getSpaces } from "../composables/useSpaces"
import { useRouter } from "vue-router"

export default {
    props: {
        id: {
            type: String
        },
        userId: {
            type: String
        }
    },
    setup(props) {

        const router = useRouter()
        const { reservationDetail } = getReservationDetail(props.userId, props.id)
        const { spaces } = getSpaces()

        function getSpaceNameById(id) {
            return spaces.value.filter(function (space) {
                return space.id == id
            })
        }

        function removeReservation(id) {
            console.log("Odstranuji reservaci " + id)
            try {
                fetch(process.env.VUE_APP_API_URL + "/users/" + props.userId + "/reservations/" + props.id, {
                    headers: {
                        'Authorization': 'Bearer ' + user.value.accessToken
                    },
                    method: 'DELETE',
                    mode: 'cors'
                })
                .then(res => {
                    console.log(res)
                    if(res.ok){
                        console.log("Response was OK")
                        router.push({ name: 'Home'})
                    } else {
                        console.log("Promise.reject() call")
                        error.value = "Error during delete"
                        return Promise.reject(res)
                    }
                })
                .catch(response => {
                    console.log('Error status: ' + response.statusText + ' (' + response.status + ')')
                    error.value = 'Error status: ' + response.statusText + ' (' + response.status + ')'
                })
            } catch (error) {
                console.log(error)
            }
        }

        function approveReservation() {
            console.log("Schvaluji rezervaci " + props.id)
            reservationDetail.value.approved = true
            try {
                fetch(process.env.VUE_APP_API_URL + "/users/" + props.userId + "/reservations/" + props.id, {
                    headers: {
                        'Authorization': 'Bearer ' + user.value.accessToken,
                        'Content-Type': 'application/json'
                    },
                    method: 'POST',
                    body: JSON.stringify(reservationDetail.value)
                })
                .then(res => {
                    console.log(res)
                    if(res.ok){
                        router.push({ name: 'Home' })
                    } else {
                        console.log("Promise.reject() call")
                        return Promise.reject(res)
                    }
                })
                .catch(response => {
                    console.log('Error status: ' + response.statusText + ' (' + response.status + ')')
                    return false
                })
            } catch (error) {
                console.log(error)
                return false
            }
        }

        return { isAdmin, reservationDetail, spaces, getSpaceNameById, removeReservation, approveReservation }

    }
}
</script>

<style scoped>
.label { 
    padding-right: 10px;
    font-weight: bold;
}
</style>