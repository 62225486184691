<template>
  <form @submit.prevent="handleSubmit">
      <input type="text" required placeholder="Jméno a příjmení" v-model="displayName">
      <input type="text" required placeholder="Telefonní číslo" v-model="phoneNumber">
      <input type="email" required placeholder="Email" v-model="email"> 
      <input type="password" required placeholder="Heslo" v-model="password">
      <div v-if="success" class="response">{{ success }}</div>
      <div class="error">{{ error }}</div>
      <button>Registrovat</button>
  </form>
</template>

<script>
import { ref } from "vue"
import useSignup from "../composables/useSignup"

export default {
    setup(props, context) {
        const success = ref('')
        const { error, signup } = useSignup()

        //refs
        const displayName = ref('')
        const phoneNumber = ref('')
        const email = ref('')
        const password = ref('')

        const handleSubmit = async () => {
            await signup(email.value, password.value, displayName.value, phoneNumber.value)
            console.log('user signed up')
            if(!error.value) {
                context.emit('signup')
                success.value = "Registrace proběhla úspěšně. Čeká se na schválení administrátorem. O schválení budete informováni emailem"
            }
        }

        return { displayName, phoneNumber, email, password, handleSubmit, error, success }
    }
}
</script>

<style scoped>
.response {
    border: solid;
    background: green;
    color: white;
    font-weight: bold;
    border-radius: 25px;
    padding: 10px;
}
</style>