<template>
  <nav v-if="user">
      <div>
        <router-link class="btn btn-secondary" :to="{ name: 'Home'}"><i class="bi bi-house-door"></i></router-link>
      </div>
      <div>
        <p>{{ user.username }}</p>
        <p class="email">{{ user.email }}</p>
      </div>

      <button @click="handleClick">Logout</button>
  </nav>
  <!-- <div v-else>
    <p>NENI USER</p>
  </div> -->
</template>

<script>
import { getUser } from '../composables/useAuth'

export default {
    setup() {
        // const { error, logout } = useLogout()

        const { user } = getUser()
        console.log("Zjistuji usera")
        console.log(getUser())

        const handleClick = async () => {
              location.reload(true)
              console.log('user logged out')
        }

        return { handleClick, user }
    }
}
</script>

<style>
  nav {
    padding: 20px;
    border-bottom: 1px solid #eee;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
  }
  nav p {
    margin: 2px auto;
    font-size: 16px;
    color: #444;
  }
  nav p.email {
    font-size: 14px;
    color: #999;
  }
</style>