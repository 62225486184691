<script setup>
import { ref } from 'vue'
const props = defineProps(['items'])
const from = ref(0)
const to = ref(10)

function next() {
  if(to.value >= props.items.length){
    return;
  }
  from.value = from.value + 10;
  to.value = to.value + 10;
}
  
function previous() {
  if(from.value <= 0){
    return
  }
  from.value = from.value - 10;
  to.value = to.value - 10;
}

</script>

<template>
<div>
  <div v-if="!items.length">
    Loading...
  </div>
  <div v-for="(item, index) in items">
    <slot v-if="index>=from && index<=to" name="item" v-bind="item"/>
  </div>  
  <div class="paginatio" v-if="items.length >= 10">
    <button class="rounded-pill bg-secondary" @click="previous" :disabled="from <= 0 ? true : false">
        &lt;
    </button>
    &nbsp;<a>{{ from }} - {{ to }} ({{ items.length }})</a>&nbsp;
    <button class="rounded-pill bg-secondary" @click="next" :disabled="to >= props.items.length">
        &gt;
    </button>
  </div>
</div>
</template>

<style>
.paginatio {
    text-align: center;
}
</style>