<template>
    <div class="welcome container">
        <h2>Nová akce</h2>
        <select class="form-select" v-model="userId">
            <option disabled value="">Vyber uzivatele</option>
            <option v-for="option in users" :value="option.userId" :key="option.id">
                {{ option.username }}
            </option>
        </select>
        <br/>
        <select class="form-select" v-model="spaceId">
            <option disabled value="">Vyber prostor</option>
            <option v-for="option in spaces" :value="option.spaceId" :key="option.id">
                {{ option.label }}
            </option>
        </select>
        <input type="text" placeholder="Od kdy (2022-02-27T15:00:00)" v-model="start"/>
        <input type="text" placeholder="Do kdy (2022-02-27T17:00:00)" v-model="end"/>
        <input type="checkbox" class="btn-check" id="paid" role="switch" v-model="paid" :checked="paid" />
        <label class="btn" :class="paid ? 'btn-success' : 'btn-danger'" for="paid">{{ paid ? 'Zaplaceno' : 'Nezaplaceno' }}</label>
        <button class="btn btn-success" @click="createNewEvent" :disabled="creating">{{ creating ? 'Vytvářím...' : 'Vytvořit' }}</button>
        <div class="error">{{ error }}</div>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { user, isAdmin } from '../composables/useAuth'
import { useRouter } from "vue-router"
import { getSpaces } from '../composables/useSpaces'

export default {
    setup() {

        const router = useRouter()

        const error = ref(null);
        const creating = ref(false);

        const userId = ref('');
        const spaceId = ref('');
        const start = ref(new Date(new Date().getTime() + 120 * 60000).toISOString().slice(0, -8));
        const end = ref(new Date(new Date().getTime() + 120 * 60000).toISOString().slice(0, -8));
        const paid = ref(false);

        const users = ref([]);
        const { spaces } = getSpaces();

        function getUsers() {
            return fetch(process.env.VUE_APP_API_URL + "/users", {
                headers: {
                    'Authorization': 'Bearer ' + user.value.accessToken
                }
            })
            .then(res => {
                console.log(res)
                if(res.ok){
                    return res.json()
                } else {
                    console.log("Promise.reject() call")
                    return Promise.reject(res)
                }
            })
            .then(data => {
                console.log(data)
                users.value = data
            })
            .catch(response => {
                error.value = 'Error status: ' + response.statusText + ' (' + response.status + ')'
            })
        }

        function createNewEvent() {
            error.value = null
            console.log("Creating new event")
            creating.value = true

            return fetch(process.env.VUE_APP_API_URL + "/events", {
                method: "POST",
                mode: 'cors',
                headers: {
                    'Authorization': 'Bearer ' + user.value.accessToken,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({'userId':userId.value, 'spaceId':spaceId.value, 'start':start.value, 'end':end.value, 'paid':paid.value})
            })
            .then(res => {
                console.log(res)
                if(res.ok){
                    router.push({ name: 'Home' })
                } else {
                    console.log("Promise.reject() call")
                    return Promise.reject(res)
                }
            })
            .catch(response => {
                error.value = 'Error status: ' + response.statusText + ' (' + response.status + ')'
                creating.value = false
            })
        }

        onMounted(() => {
            if(isAdmin.value) {
                getUsers()
            }
        })

    return { error, creating, createNewEvent, userId, spaceId, start, end, paid, users, spaces }
    }
}
</script>

<style scoped>

</style>