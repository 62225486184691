import { ref } from "vue"
import { setUser, user } from "./useAuth"

const error = ref(null)

const login = async (email, password) => {
    error.value = null

    // try {
    //     console.log("Project auth: " , projectAuth)
    //     console.log("Calling signInWithEmailAndPassword")
    //     const res = await signInWithEmailAndPassword(email, password)
    //     error.value = null
    //     console.log(res)
    //     return res
    // } catch (err) {
    //     console.log(err.message)
    //     error.value = 'Incorrect login credentials'
    // }


    console.log("Signing in with email: ",email," and password",password)

    try {
        await fetch(process.env.VUE_APP_API_URL + "/auth/login", {
            method: "POST",
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({'email':email,'password':password})
        })
        .then(res => {
            console.log(res)
            if(res.ok){
                return res.json()
            } else {
                console.log("Promise.reject() call")
                return Promise.reject(res)
            }
        })
        .then(data => {
            console.log("Storing user into localstorage: ", data)
            // localStorage.setItem('user', data)
            console.log("Setting user...")
            setUser(data)
            // console.log("Token from localstorage: ", localStorage.getItem('token').json().accessToken)
            // setUser(data)
            console.log("User set.")
            console.log("Setted user: ", user)
            console.log(user)
            return user;
        })
        .catch(response => {
            error.value = 'Error status: ' + response.statusText + ' (' + response.status + ')'
        })
    } catch (error) {
        console.log("Login error: ", error)
        error.value = error
    }
}

const useLogin = () => {
    return { error, login }
}

export default useLogin