<template>
  <div class="container" style="padding: 10px">
      <h3 class="p-3 text-center">Prostory</h3>
      <table class="table table-bordered">
          <thead>
              <tr>
                  <td>ID</td>
                  <td>Popis</td>
              </tr>
          </thead>
          <tbody>
              <tr v-for="space in spaces" :key="space.id">
                  <td><router-link :to="{ name: 'SpaceDetail', params: { id: space.id}}" >
                    {{ space.spaceId }}
                  </router-link></td>
                  <td>{{ space.label }}</td>
              </tr>
          </tbody>
      </table>
  </div>
</template>

<script>
import { onMounted } from '@vue/runtime-core';
import { ref } from 'vue';
import { user, isAdmin } from '../composables/useAuth'

export default {
    setup() {

        const error = ref(null);
        const spaces = ref([]);

        function getSpaces() {
            return fetch(process.env.VUE_APP_API_URL + "/spaces", {
                headers: {
                    'Authorization': 'Bearer ' + user.value.accessToken
                }
            })
            .then(res => {
                console.log(res)
                if(res.ok){
                    return res.json()
                } else {
                    console.log("Promise.reject() call")
                    return Promise.reject(res)
                }
            })
            .then(data => {
                console.log(data)
                spaces.value = data
            })
            .catch(response => {
                error.value = 'Error status: ' + response.statusText + ' (' + response.status + ')'
            })
        }

        onMounted(() => {
            if(isAdmin.value) {
                getSpaces()
            }
        })

        return { error, spaces }
    }
}
</script>

<style scoped>
.inactive {
    color: white;
    background: gray;
}
</style>