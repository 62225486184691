import jwt_decode from "jwt-decode";
import { ref } from "vue"

const user = ref(null);
let token = null;
const isAdmin = ref(false);

// function getUser(authToken) {
//     console.log("Obtained token from local storage: " + authToken)
//     if(!authToken || authToken === "undefined"){
//         console.log("Token je na hovno")
//         return null
//     }
//     return jwt_decode(authToken)
// }

function getUserToken() {
    if(user.value){
        console.log("Uzivatel existuje. Ziskavam token")
        return user.value.accessToken;
    }
    return null;
}

const getUser = () => {
    return { user };
}

function setUser(user2) {
    // user = jwt_decode(authToken)
    // console.log("Decoded user token: ", user)
    // token = authToken;
    // console.log("Obtained user which will be assigned into user." + JSON.parse(user))
    console.log("User variable before assigning: " + user2)
    console.log(user2);
    console.log(JSON.stringify(user2))
    user.value = user2;
    token = user2.accessToken;
    isAdmin.value = user2.roles.includes('ADMIN')
    console.log("isAdmin.value:")
    console.log(isAdmin.value)
    console.log("User token: ");
    console.log(user2.accessToken)
    console.log("After asssigning into variable")
    console.log(user.value)
    console.log("After assigning")
    // console.log("User after assigning: " + user)
    // return user;
}

export { user, setUser, token, getUser, isAdmin}