<template>
  <div class="container" style="padding: 10px">
      <h3 class="p-3 text-center">Admin akce</h3>
      <router-link class="btn btn-outline-success" :to="{ name: 'NewSpaceEvent' }" >vytvořit novou akci</router-link>
      <br><br>
  </div>
</template>

<style scoped>
.btn {
    width: -webkit-fill-available;
}
</style>