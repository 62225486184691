import { ref, computed } from "vue"

export function getAllReservations() {
    const error = ref(null);
    const reservations = ref([]);

    try {
        fetch(process.env.VUE_APP_API_URL + "/public/reservations", {
            headers: {
                // 'Authorization': 'Bearer ' + user.value.accessToken
            }
        })
        .then(res => {
            console.log(res)
            if(res.ok){
                return res.json()
            } else {
                console.log("Promise.reject() call")
                return Promise.reject(res)
            }
        })
        .then(data => {
            console.log(data)
            console.log("Assigning data to reservations")
            reservations.value = data
            console.log("Assigned data to reservations")
            console.log(data)
        })
        .catch(response => {
            error.value = 'Error status: ' + response.statusText + ' (' + response.status + ')'
        })
    } catch (error) {
        console.log(error)
        error.value = error
    }

    const perpetual = computed(() => {
        return reservations.value.filter(function (res) {
            return res.dayOfWeek != null
        })
    })

    const onetime = computed(() => {
        return reservations.value.filter(function (res) {
            return res.dayOfWeek == null
        })
    })

    return { reservations, perpetual, onetime, error }
}