import { ref } from 'vue';
import { user } from "./useAuth"

function getSpaces() {
    const spaces = ref([])
    const error = ref(null)
    
    try {
        fetch(process.env.VUE_APP_API_URL + "/spaces", {
            headers: {
                'Authorization': 'Bearer ' + user.value.accessToken
            }
        })
        .then(res => {
            console.log(res)
            if(res.ok){
                return res.json()
            } else {
                console.log("Promise.reject() call")
                return Promise.reject(res)
            }
        })
        .then(data => {
            console.log(data)
            spaces.value = data
        })
        .catch(response => {
            error.value = 'Error status: ' + response.statusText + ' (' + response.status + ')'
        })
    }catch(error){
        console.log(error)
    }

    return { spaces, error }
}

export { getSpaces }