import { ref } from "vue"
import { user } from "./useAuth"

export function getUserEvents(id) {
    const error = ref(null);
    const userEvents = ref([]);

    try {
        fetch(process.env.VUE_APP_API_URL + "/users/" + id + "/events", {
            headers: {
                'Authorization': 'Bearer ' + user.value.accessToken
            }
        })
        .then(res => {
            console.log(res)
            if(res.ok){
                return res.json()
            } else {
                console.log("Promise.reject() call")
                return Promise.reject(res)
            }
        })
        .then(data => {
            console.log(data)
            console.log("Assigning data to userEvents")
            userEvents.value = data
            console.log("Assigned data to userEvents")
            console.log(data)
        })
        .catch(response => {
            error.value = 'Error status: ' + response.statusText + ' (' + response.status + ')'
        })
    } catch (error) {
        console.log(error)
        error.value = error
    }

    return { userEvents, error }
}