<template>
  <div class="container" style="padding: 10px">
      <div v-if="eventDetail != null">
      <button v-if="isAdmin" :disabled="removingEvent" class="btn btn-danger" @click="removeEvent(eventDetail.id)"><i class="bi bi-trash"><small v-if="removingEvent"> Vymazávám</small></i></button>
      <span v-if="isAdmin">&nbsp;</span>
      <button v-if="eventDetail.end != null && eventDetail.paid == false" :disabled="sendingEmailInvoice" class="btn btn-primary" @click="sendEmailInvoice(eventDetail.id)"><i class="bi bi-envelope"><small>{{ sendingEmailInvoice ? ' Posílám info...' : ' Zaslat info o platbě'}}</small></i></button>
      <br/>
      <br/>
      <div v-if="errorMessage" class="error">{{ errorMessage }}</div>
      <table>
          <tr>
              <td class="label">ID</td>
              <td>{{ eventDetail.id }}</td>
          </tr>
          <tr>
              <td class="label">Prostor</td>
              <td>{{ eventDetail.spaceId }}</td>
          </tr>
          <tr>
              <td class="label">ID uživ.</td>
              <td>{{ eventDetail.userId }}</td>
          </tr>
          <tr>
              <td class="label">Jméno</td>
              <td>{{ eventDetail.user.username }}</td>
          </tr>
          <tr>
              <td class="label">Telefon</td>
              <td>{{ eventDetail.user.phoneNumber }}</td>
          </tr>
          <tr>
              <td class="label">Email</td>
              <td>{{ eventDetail.user.email }}</td>
          </tr>
          <tr>
              <td class="label">Začátek</td>
              <td>{{ eventDetail.start }}</td>
          </tr>
          <tr>
              <td class="label">Konec</td>
              <td v-if="eventDetail.end != null">{{ eventDetail.end }}</td>
              <td v-if="eventDetail.end == null">
                  <button class="btn btn-success btn-sm" v-on:click="endEvent">
                    <i class="bi bi-box-arrow-right"></i>
                    Ukončit
                  </button>
               </td>
          </tr>
          <tr>
              <td class="label">Cena</td>
              <td>{{ eventDetail.price }},- Kč</td>
          </tr>
          <tr>
              <td class="label">Zaplaceno</td>
              <td>{{ eventDetail.paid ? 'ANO' : 'NE' }}
                  <button v-on:click="handlePaid" class="btn btn-danger btn-sm" v-if="eventDetail.paid == true && isAdmin"><i class="bi bi-x-octagon-fill"></i></button>
                  <button v-on:click="handlePaid" class="btn btn-success btn-sm" v-if="eventDetail.paid == false && isAdmin"><i class="bi bi-check2"></i></button>
              </td>
          </tr>
      </table>
      <br>
      <div v-if="eventDetail.end && !eventDetail.paid">
        <span>QR kod pro platbu:</span>
        <br>
        <img :src="'data:image/png;base64, ' + eventDetail.base64QRCode" alt="QR platba"/>
      </div>
      </div>
      <div v-else>
          <p>Loading...</p>
      </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue"
import { user, isAdmin } from "../composables/useAuth"
import { useRouter } from "vue-router"

export default {
    props: ["id"],
    setup(props) {

        const router = useRouter()

        const eventDetail = ref(null)
        const removingEvent = ref(false)
        const errorMessage = ref(null)

        const sendingEmailInvoice = ref(false)

        function getEventDetail(id) {
            return fetch(process.env.VUE_APP_API_URL + "/events/" + id, {
                headers: {
                    'Authorization': 'Bearer ' + user.value.accessToken
                }
            })
            .then(res => {
                console.log(res)
                if(res.ok){
                    return res.json()
                } else {
                    console.log("Promise.reject() call")
                    return Promise.reject(res)
                }
            })
            .then(data => {
                console.log("Obtained event:")
                console.log(data)
                eventDetail.value = data
            })
            .catch(response => {
                error.value = 'Error status: ' + response.statusText + ' (' + response.status + ')'
            })
        }

        function handlePaid() {
            console.log("Handling paid");
            return fetch(process.env.VUE_APP_API_URL + "/events/" + eventDetail.value.id + "/paid", {
                method: "POST",
                headers: {
                    'Authorization': 'Bearer ' + user.value.accessToken,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(!eventDetail.value.paid)
            })
            .then(res => {
                console.log(res)
                if(res.ok){
                    return res.json()
                } else {
                    console.log("Promise.reject() call")
                    return Promise.reject(res)
                }
            })
            .then(data => {
                console.log("Obtained event:")
                console.log(data)
                eventDetail.value = data
            })
            .catch(response => {
                error.value = 'Error status: ' + response.statusText + ' (' + response.status + ')'
            })
        }

        function endEvent() {
            console.log("Ending an event " + eventDetail.value.id)
            return fetch(process.env.VUE_APP_API_URL + "/events/" + eventDetail.value.id + "/end", {
                method: "POST",
                headers: {
                    'Authorization': 'Bearer ' + user.value.accessToken,
                    'Content-Type': 'application/json'
                }
            })
            .then(res => {
                console.log(res)
                if(res.ok){
                    return res.json()
                } else {
                    console.log("Promise.reject() call")
                    return Promise.reject(res)
                }
            })
            .then(data => {
                console.log("Obtained event:")
                console.log(data)
                eventDetail.value = data
            })
            .catch(response => {
                error.value = 'Error status: ' + response.statusText + ' (' + response.status + ')'
            })
        }

        function removeEvent(eventId) {
            console.log("Removing event " + eventId)
            removingEvent.value = true
            return fetch(process.env.VUE_APP_API_URL + "/events/" + eventId, {
                method: "DELETE",
                headers: {
                    'Authorization': 'Bearer ' + user.value.accessToken
                }
            })
            .then(res => {
                console.log(res)
                if(res.ok){
                    router.push({ name: 'Home' })
                } else {
                    console.log("Promise.reject() call")
                    return Promise.reject(res)
                }
            })
            .catch(response => {
                errorMessage.value = 'Error status: ' + response.statusText + ' (' + response.status + ')'
                removingEvent.value = false
            })
        }

        function sendEmailInvoice(eventId) {
            console.log("Sending pay request over email");
            errorMessage.value = null
            sendingEmailInvoice.value = true

            return fetch(process.env.VUE_APP_API_URL + "/events/" + eventDetail.value.id + "/actions/sendEmailInvoice", {
                method: "POST",
                headers: {
                    'Authorization': 'Bearer ' + user.value.accessToken,
                    'Content-Type': 'application/json'
                },
            })
            .then(res => {
                console.log(res)
                if(res.ok){
                    sendingEmailInvoice.value = false
                } else {
                    console.log("Promise.reject() call")
                    sendingEmailInvoice.value = false
                    errorMessage.value = "Nepodařilo se zaslat žádost o platbu přes email"
                    return Promise.reject(res)
                }
            })
            .catch(response => {
                error.value = 'Error status: ' + response.statusText + ' (' + response.status + ')'
                errorMessage.value = "Nepodařilo se zaslat žádost o platbu přes email. " + 'Error status: ' + response.statusText + ' (' + response.status + ')'
            })
        }

        onMounted(() => {
            console.log("Obtained event id from props: ")
            console.log(props.id)
            getEventDetail(props.id)
        })

        return { user, isAdmin, eventDetail, handlePaid, endEvent, removeEvent, sendEmailInvoice, removingEvent, sendingEmailInvoice ,errorMessage }
    }
}
</script>

<style scoped>
.label { 
    padding-right: 10px;
    font-weight: bold;
}
</style>