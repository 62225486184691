<template>
  <div class="container">
    <table class="table table-borderless">
        <tbody>
          <tr>
            <td class="label">Jméno</td>
            <td>{{ currentUser.username }}</td>
          </tr>
          <tr>
            <td class="label">Email</td>
            <td>{{ currentUser.email }}</td>
          </tr>
          <tr>
              <td class="label">Telefon</td>
              <td>{{ currentUser.phoneNumber }}</td>
          </tr>
          <tr>
              <td class="label">ID</td>
              <td>{{ currentUser.userId }}</td>
          </tr>
          <tr>
              <td class="label">PIN</td>
              <td>{{ currentUser.pin }}</td>
          </tr>
        </tbody>
    </table>
  </div>
</template>

<script>
import { onMounted } from '@vue/runtime-core';
import { ref } from 'vue';
import { user } from '../composables/useAuth'
// import getUser from "../composables/getUser"

export default {
    setup() {

        const error = ref(null);
        const currentUser = ref({});

        function getUser() {
            return fetch(process.env.VUE_APP_API_URL + "/profile", {
                headers: {
                    'Authorization': 'Bearer ' + user.value.accessToken
                }
            })
            .then(res => {
                console.log(res)
                if(res.ok){
                    return res.json()
                } else {
                    console.log("Promise.reject() call")
                    return Promise.reject(res)
                }
            })
            .then(data => {
                console.log(data)
                console.log("Assigning data to currentUser")
                currentUser.value = data
                console.log("Assigned data to currentUser")
                console.log(data)
                // return data;
            })
            .catch(response => {
                error.value = 'Error status: ' + response.statusText + ' (' + response.status + ')'
            })
        }

        onMounted(() => {
            getUser()
        })

        return { error, currentUser }
    }
}
</script>

<style scoped>
.label {
    font-weight: bold;
}
</style>