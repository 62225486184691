<template>
  <form @submit.prevent="handleSubmit">
      <input type="email" required placeholder="Email" v-model="email"> 
      <input type="password" required placeholder="Heslo" v-model="password">
      <div class="error">{{ error }}</div>
      <div class="waiter">{{ waiting }}</div>
      <button :class="{ disabled: disabled }" :disabled="disabled">Přihlásit</button>
  </form>
</template>

<script>
import { ref } from "vue"
import useLogin from "../composables/useLogin"

export default {
    setup(props, context) {
        //refs
        const email = ref('')
        const password = ref('')
        const waiting = ref(null);
        const disabled = ref(false);

        const { error, login } = useLogin();

        const handleSubmit = async () => {
            waiting.value = "Vyrizuji..."
            disabled.value = true
            const sleep = async (ms) => {() => {new Promise(resolve => setTimeout(resolve, ms))}}
            await sleep(2000);
            await login(email.value, password.value);
            if(!error.value){
                console.log('user logged in')
                waiting.value = null
                disabled.value = false
                context.emit('login')
            }
            waiting.value = null
            disabled.value = false
        }

        return { email, password, handleSubmit, error, waiting, disabled }
    }
}
</script>

<style scoped>
.disabled {
    background-color: antiquewhite;
}
</style>